import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import Button from "../components/elements/Button"
import TextInput from "../components/elements/Inputs/TextInput";
import Checkbox from "../components/elements/Inputs/Checkbox";
import Layout from "../components/layout"
import axios from "axios";
import { API_BASE } from "../spectory-config";
import LoadingSpinner from "../components/elements/LoadingSpinner";
import ThirdContainerSidebar from "../components/elements/ThirdContainerSidebar";
import PageInfo from "../components/elements/PageInfo";
import { navigate, withPrefix } from "gatsby";
import { useDispatch } from "react-redux";
import { setNotification } from "../store/actions";

const Login = (props) => {
    const { register, handleSubmit } = useForm();
    const { register: registerLogin, handleSubmit: handleSubmitLogin } = useForm();
    const { register: registerResetPassword, handleSubmit: handleSubmitResetPassword } = useForm();


    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [registerError, setRegisterError] = useState('');
    const [showSuccess, setShowSuccess] = useState('');

    const dispatch = useDispatch();

    console.log("PROPS LOGIN", props.uri)

    const submitRegister = async (data) => {

        setLoading(true);

        var config = {
            method: 'post',
            url: `${API_BASE}/auth/register`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        return axios(config)
            .then(function (response) {
                console.log(response)
                if (response.data.c === 200) {
                    setShowSuccess(true);
                } else {
                    setRegisterError(response.data.errorMessage)
                }

                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
                setRegisterError("Etwas ist schief gelaufen. Bitte versuchen Sie es erneut.")
            });
    }


    const submitLogin = async (data) => {

        setLoginError('');
        setLoading(true);
        var config = {
            method: 'post',
            url: `${API_BASE}/auth`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return axios(config)
            .then(function (response) {
                console.log(response)

                if (response.data.c === 200) {
                    if (data.stayLoggedIn) {
                        if (localStorage !== undefined) {
                            localStorage.setItem('token', response.data.token);
                            localStorage.setItem('userdata', JSON.stringify(response.data.userData));
                            sessionStorage.removeItem('token');
                            sessionStorage.removeItem('userdata');
                        }
                    } else {
                        if (localStorage !== undefined) {
                            sessionStorage.setItem('token', response.data.token);
                            sessionStorage.setItem('userdata', JSON.stringify(response.data.userData));
                            localStorage.removeItem('token');
                            localStorage.removeItem('userdata');
                        }
                    }

                    navigate(withPrefix('/konto'));

                } else {
                    setLoginError('E-Mail oder Passwort sind falsch. Bitte überprüfen Sie Ihre Eingabe.')
                    setLoading(false);
                }

            })
            .catch(function (error) {
                console.log(error);
                setLoginError('E-Mail oder Passwort sind falsch. Bitte überprüfen Sie Ihre Eingabe.')
                setLoading(false);
            });
    }

    const submitResetPassword = async (data) => {

        setLoading(true);

        var config = {
            method: 'post',
            url: `${API_BASE}/auth/passwordreset`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        return axios(config)
            .then(function (response) {
                console.log("response", response)
                if (response.data.c === 200) {
                    dispatch(setNotification({
                        title: 'E-Mail zum Zurücksetzen Ihres Passwortes wurde gesendet. Bitte sehen Sie auch in Ihrem Spam-ORdner nach.',
                        type: "success",
                        show: true
                    }));
                } else {
                    dispatch(setNotification({
                        title: 'Etwas ist schief gelaufen, bitte versuchen Sie es erneut.',
                        type: "error",
                        show: true
                    }));
                }

                setLoading(false);
            })
            .catch(function (error) {
                console.log("error", error)
                setLoading(false);
                dispatch(setNotification({
                    title: 'Etwas ist schief gelaufen, bitte versuchen Sie es erneut.',
                    type: "error",
                    show: true
                }));
            });
    }


    const renderLogin = <>
        <h2>Anmelden</h2>
        <br />
        <br />
        <br />
        <form onSubmit={handleSubmitLogin(submitLogin)}>
            <TextInput
                required
                type="email"
                label="E-Mail"
                {...registerLogin('email')}
            />
            <TextInput
                type="password"
                label="Passwort"
                required
                {...registerLogin('password')}
            />
            <Checkbox
                {...registerLogin('stayLoggedIn')}
                label="Angemeldet bleiben"
                style={{ marginTop: 20, marginBottom: 20 }}
            />
            <Button
                title="Login"
                type="submit"
                additionalStyle={{ width: '100%' }}
            />
            <br />
            <Button
                title="Passwort vergessen?"
                type="tertiary"
                destination={'/forgot-password'}
            />

        </form>
        {
            loginError !== '' &&
            <div style={{ color: 'red' }}>
                {loginError}
            </div>
        }
    </>;

    const renderForgotPassword = <>
        <h2>Passwort vergessen?</h2>
        <br />
        <br />
        <p>
            Geben Sie Ihre E-Mail-Adresse ein, mit der Sie Ihr Konto erstellt haben. So können wir Ihnen einen Link zum Zurücksetzen Ihres Passworts schicken.
        </p>
        <br />
        <br />
        <br />
        <form onSubmit={handleSubmitResetPassword(submitResetPassword)}>
            <TextInput
                required
                type="email"
                label="E-Mail"
                {...registerResetPassword('email')}
            />
            <Button
                title="Passwort zurücksetzen"
                type="submit"
                additionalStyle={{ width: '100%' }}
            />
            <br />
            <Button
                title="Zurück zur Anmeldung"
                type="tertiary"
                destination={'/login'}
            />

        </form>
        {
            loginError !== '' &&
            <div style={{ color: 'red' }}>
                {loginError}
            </div>
        }
    </>;

    return (
        <Layout>
            {showSuccess && <PageInfo
                fixed={true}
                type="success"
                onClick={() => setShowSuccess(false)}
                title={'Willkommen! Wir haben Ihnen eine E-Mail geschickt, um Ihre Account zu aktivieren. Bitte öffnen Sie Ihr E-Mail-Programm und klicken Sie in unserer Mail auf den Button "Konto aktivieren'}
            />
            }
            <ThirdContainerSidebar
                sideBarContent={<div style={{ marginTop: 100, marginBottom: 100 }}>{props.uri === '/login' ? renderLogin : renderForgotPassword} </div>}
            >

                <div className="container" style={{ marginTop: 100, marginBottom: 100 }}>
                    <div className="col12">
                        <div>
                            <h2>Sie haben eine Kundenkarte?</h2>
                            <p style={{ maxWidth: 470 }}>
                                Erstellen Sie jetzt ein Online-Konto und verknüpfen Sie dieses mit Ihrer bestehenden Kundenkarte.
                            </p>
                            <br />
                            <Button
                                title="Jetzt Online-Konto aktivieren"
                                destination="/activate-with-card"
                                type="primary"
                            />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <h2>Neues Kundenkonto erstellen</h2>
                            <br />
                            <form onSubmit={handleSubmit(submitRegister)}>
                                <div className={"container fluid mobileNoPadding"} style={{ padding: 0 }}>
                                    <div className="col6" style={{ margin: 0 }}>
                                        <TextInput required {...register('first_name')} label="Vorname" />
                                    </div>
                                    <div className="col6" style={{ margin: 0 }}>
                                        <TextInput required {...register('last_name')} label="Nachname" />
                                    </div>
                                </div>
                                <TextInput type="email" {...register('email')} label="E-Mail" />
                                <TextInput
                                    description="Passwörter müssen mindestens 10 Zeichenland sein und 1 Großbuchstben, 1 Zahl und 1 Sonderzeichen enthalten."
                                    type="password"
                                    {...register('password')}
                                    label="Passwort"
                                />
                                <Checkbox
                                    {...register('gdpr')}
                                    label="Ich habe die AGB & Datenschutzbestimmungen gelesen, und akzeptiere diese."
                                    required
                                    style={{ marginTop: 20, marginBottom: 20 }}
                                />
                                <Button
                                    title="Registrieren"
                                    type="submit"
                                />
                            </form>
                            {
                                registerError !== '' &&
                                <div style={{ color: 'red' }}>
                                    {registerError}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </ThirdContainerSidebar>
            <LoadingSpinner active={loading} type={"page"} />
        </Layout>
    )
}

export default Login
